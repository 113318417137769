<template>
  <div id="management">
    <div class="content">
      <div class="container">
        <p class="tab-setting" v-if="updateIntellectual == true">
          แก้ไขทรัพย์สินทางปัญญา
        </p>
        <p class="tab-setting" v-else>เพิ่มทรัพย์สินทางปัญญา</p>
        <div class="box-manage-admin">
          <el-row :gutter="30">
            <el-col :span="24" class="set-box name-list">
              <p>ข้อมูลทรัพย์สินทางปัญญา</p>
            </el-col>
            <el-form
              ref="form"
              :label-position="'top'"
              :model="form"
              :rules="rules"
            >
              <el-col :span="24" class="set-box">
                <el-row :gutter="15">
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item
                      label="ชื่อศูนย์บริการทางการแพทย์"
                      prop="serviceId"
                    >
                      <el-select
                        v-model="form.serviceId"
                        placeholder="กรุณาเลือกศูนย์บริการ"
                      >
                        <el-option
                          v-for="item in dataService"
                          :key="item._id"
                          :label="item.nameTH"
                          :value="item._id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="ชื่อทรัพย์สินทางปัญญา" prop="name">
                      <el-input v-model="form.name"> </el-input> </el-form-item
                  ></el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="เลขที่คำขอ" prop="requestNumber">
                      <el-input
                        v-model="form.requestNumber"
                        maxlength="10"
                        @keypress.native="isNumber($event)"
                      >
                      </el-input> </el-form-item
                  ></el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="วันที่ยื่นคำขอ" prop="requestDate">
                      <el-date-picker
                        v-model="form.requestDate"
                        type="date"
                        placeholder="Pick a day"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="วันที่รับคำขอ" prop="reciveDate">
                      <el-date-picker
                        v-model="form.reciveDate"
                        type="date"
                        placeholder="Pick a day"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item
                      label="วันที่ประกาศโฆษณา"
                      prop="announcementDate"
                    >
                      <el-date-picker
                        v-model="form.announcementDate"
                        type="date"
                        placeholder="Pick a day"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item
                      label="วันที่รับจดทะเบียน"
                      prop="registrationDate"
                    >
                      <el-date-picker
                        v-model="form.registrationDate"
                        type="date"
                        placeholder="Pick a day"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item
                      label="เลขที่จดทะเบียน"
                      prop="registrationNumber"
                    >
                      <el-input
                        v-model="form.registrationNumber"
                        maxlength="10"
                        @keypress.native="isNumber($event)"
                      >
                      </el-input> </el-form-item
                  ></el-col>
                  <el-col :span="24">
                    <el-form-item label="รหัส PCT" prop="pctNumber">
                      <el-input
                        v-model="form.pctNumber"
                        maxlength="10"
                        @keypress.native="isNumber($event)"
                      >
                      </el-input> </el-form-item
                  ></el-col>
                  <el-col
                    :span="24"
                    v-for="(roundcateIPC, index) in form.cateIPC"
                    :key="index+'IPC'"
                  >
                    <el-row :gutter="15">
                      <el-col :span="24" :md="18">
                        <el-form-item label="รหัสกลุ่ม IPC">
                          <el-input v-model="form.cateIPC[index]"> </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24" :md="6" class="box-openday">
                        <el-row :gutter="15">
                          <el-col :span="12" :sm="12" :md="12">
                            <el-form-item label="เปิดปิด">
                              <el-button
                                type="primary"
                                class="add-openday"
                                @click="addCateIPC()"
                                >เพิ่ม</el-button
                              >
                            </el-form-item></el-col
                          >
                          <el-col :span="12" :sm="12" :md="12">
                            <el-form-item label="เปิดปิด">
                              <el-button
                                type="primary"
                                class="del-openday"
                                @click="delCateIPC(index)"
                                >ลบ</el-button
                              >
                            </el-form-item></el-col
                          >
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="ชื่อเจ้าของสิทธิ" prop="cateInventor">
                      <el-input v-model="form.cateInventor">
                      </el-input> </el-form-item
                  ></el-col>
                  <el-col
                    :span="24"
                    v-for="(roundInventor, index) in form.inventor"
                    :key="index"
                  >
                    <el-row :gutter="15">
                      <el-col :span="24" :md="18">
                        <el-form-item label="ชื่อผู้ประดิษฐ์">
                          <el-input v-model="form.inventor[index]"> </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24" :md="6" class="box-openday">
                        <el-row :gutter="15">
                          <el-col :span="12" :sm="12" :md="12">
                            <el-form-item label="เปิดปิด">
                              <el-button
                                type="primary"
                                class="add-openday"
                                @click="addInventor()"
                                >เพิ่ม</el-button
                              >
                            </el-form-item></el-col
                          >
                          <el-col :span="12" :sm="12" :md="12">
                            <el-form-item label="เปิดปิด">
                              <el-button
                                type="primary"
                                class="del-openday"
                                @click="delInventor(index)"
                                >ลบ</el-button
                              >
                            </el-form-item></el-col
                          >
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="24">
                    <el-upload
                      class="upload-demo"
                      action="#"
                      :auto-upload="false"
                      :on-change="uploadImage"
                      :on-remove="handleRemove"
                      :limit="1"
                      :file-list="fileList"
                    >
                      <el-button size="small" class="btn-upload"
                        >คลิกเพื่ออัปโหลดไฟล์ทรัพย์สินทางปัญญา
                      </el-button>
                    </el-upload>
                  </el-col>
                </el-row>
              </el-col>
            </el-form>
          </el-row>
        </div>
        <div class="setbtn">
          <router-link to="/manageintellectual">
            <button class="btn btn-color-dark btn-cancle">
              <span>ยกเลิก</span>
            </button>
          </router-link>
          <button
            class="btn btn-color-dark btn-save"
            @click="updateFormIntellectual()"
            v-if="updateIntellectual == true"
          >
            <span>บันทึก</span>
          </button>
          <button
            class="btn btn-color-dark btn-save"
            @click="createIntellectual()"
            v-else
          >
            <span>บันทึก</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/service/axios.js";
export default {
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  mounted() {
    this.getIntellectualById();
    this.getDataService();
  },
  components: {},
  props: {
    routeParams: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      updateIntellectual: false,
      callImage: process.env.VUE_APP_BASE_URL_GET,
      image: "",
      fileList: [],
      dataService: [],
      form: {
        serviceId: "",
        requestNumber: "",
        name: "",
        requestDate: "",
        reciveDate: "",
        announcementDate: "",
        registrationDate: "",
        registrationNumber: "",
        pctNumber: "",
        cateIPC: [""],
        cateInventor: "",
        inventor: [""],
        attach: "",
      },
      rules: {
        name: {
          required: true,
          message: "กรุณากรอกชื่อทรัพย์สินทางปัญญา",
        },
        serviceId: [
          {
            required: true,
            message: "กรุณาเลือกศูนย์บริการ",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    getDataService() {
      HTTP.get(`services/${0}/${0}/${"all"}/0`)
        .then((res) => {
          if (res.data.success) {
            this.dataService = res.data.obj;
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("getDataService error", e);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
    getIntellectualById() {
      if (typeof this.routeParams.id != "undefined") {
        HTTP.get(`intellectual/by/${this.routeParams.id}`)
          .then((res) => {
            if (res.data.success) {
              this.form = res.data.obj;
              this.updateIntellectual = true;
              if (typeof this.form.attach != "undefined") {
                this.fileList.push({
                  name: this.form.attach.file,
                });
              }
            } else {
              this.alertFailError();
            }
          })
          .catch((e) => {
            this.alertCatchError(e);
            console.log("fetchService error", e);
          })
          .finally(() => {
            setTimeout(() => {
              this.loading = false;
            }, 500);
          });
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    addCateIPC() {
      this.form.cateIPC.push("");
    },
    delCateIPC(index) {
      if (this.form.cateIPC.length > 1) {
        this.form.cateIPC.splice(index, 1);
      }
    },
    addInventor() {
      this.form.inventor.push("");
    },
    delInventor(index) {
      if (this.form.inventor.length > 1) {
        this.form.inventor.splice(index, 1);
      }
    },
    updateFormIntellectual() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
          if (this.form.requestDate == null) {
            this.form.requestDate = "";
          }
          if (this.form.reciveDate == null) {
            this.form.reciveDate = "";
          }
          if (this.form.announcementDate == null) {
            this.form.announcementDate = "";
          }
          if (this.form.registrationDate == null) {
            this.form.registrationDate = "";
          }
          var formData = new FormData();
          formData.append("serviceId", this.form.serviceId);
          formData.append("requestNumber", this.form.requestNumber);
          formData.append("name", this.form.name);
          formData.append("requestDate", this.form.requestDate);
          formData.append("reciveDate", this.form.reciveDate);
          formData.append("announcementDate", this.form.announcementDate);
          formData.append("registrationDate", this.form.registrationDate);
          formData.append("registrationNumber", this.form.registrationNumber);
          formData.append("pctNumber", this.form.pctNumber);
          this.form.cateIPC.map((data) => {
            formData.append("cateIPC", data);
          });
          formData.append("cateInventor", this.form.cateInventor);
          this.form.inventor.map((data) => {
            formData.append("inventor", data);
          });
          formData.append("attach", this.form.attach);

          HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
          HTTP.put(`intellectual/${this.routeParams.id}`, formData, config)
            .then((res) => {
              if (res.data.success) {
                console.log(res.data);
                this.$message({
                  message: "แก้ไขข้อมูลสำเร็จ",
                  type: "success",
                  duration: 4000,
                });
                this.$router.push(`/manageintellectual`);
              } else {
                this.alertFailError();
              }
            })
            .catch((e) => {
              this.alertCatchError(e);
              console.log("update intellectual error", e);
            });
        }
      });
    },
    createIntellectual() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
          var formData = new FormData();
          formData.append("serviceId", this.form.serviceId);
          formData.append("requestNumber", this.form.requestNumber);
          formData.append("name", this.form.name);
          formData.append("requestDate", this.form.requestDate);
          formData.append("reciveDate", this.form.reciveDate);
          formData.append("announcementDate", this.form.announcementDate);
          formData.append("registrationDate", this.form.registrationDate);
          formData.append("registrationNumber", this.form.registrationNumber);
          formData.append("pctNumber", this.form.pctNumber);
          this.form.cateIPC.map((data) => {
            formData.append("cateIPC", data);
          });
          formData.append("cateInventor", this.form.cateInventor);
          this.form.inventor.map((data) => {
            formData.append("inventor", data);
          });
          formData.append("attach", this.form.attach);

          HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
          HTTP.post(`intellectual/property`, formData, config)
            .then((res) => {
              if (res.data.success) {
                console.log(res.data);
                this.$message({
                  message: "บันทึกข้อมูลสำเร็จ",
                  type: "success",
                  duration: 4000,
                });
                this.$router.push(`/manageintellectual`);
              } else {
                this.alertFailError();
              }
            })
            .catch((e) => {
              this.alertCatchError(e);
              console.log("create intellectual error", e);
            });
        }
      });
    },

    uploadImage(file) {
      this.image = file;
      this.form.attach = this.image.raw;
    },
    handleRemove() {
      this.form.attach = {};
    },
  },
};
</script><style scoped>
.btn-save {
  margin-top: 20px;
  width: 154px;
  font-size: 16px;
  margin-left: 15px;
}
.btn-cancle {
  margin-top: 20px;
  width: 154px;
  font-size: 16px;
  background: transparent;
  color: #7e828f;
  border: 1px solid #7e828f;
}
.btn-upload {
  width: 100%;
  display: block;
  padding: 11px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
  color: #ffffff;
  font-family: "Kanit", sans-serif;
  background: #9e76b4;
  cursor: pointer;
}
</style>